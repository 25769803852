import React, { Component } from 'react';
import Slider from 'react-slick';
import laurel01 from '../../assets/laurels/laurels01.png';
import laurel02 from '../../assets/laurels/laurels02.png';
import laurel03 from '../../assets/laurels/laurels03.png';
import laurel04 from '../../assets/laurels/laurels04.png';
import laurel05 from '../../assets/laurels/laurels05.png';
import laurel06 from '../../assets/laurels/laurels06.png';
import laurel07 from '../../assets/laurels/laurels07.png';
import laurel08 from '../../assets/laurels/laurels08.png';
import laurel09 from '../../assets/laurels/laurels09.png';
import laurel10 from '../../assets/laurels/laurels10.png';

class HomeLaurels extends Component {
  state = {
    laurel: laurel01,
  };
  componentDidMount() {}
  render() {
    var settings = {
      dots: true,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
    };
    var laurels = [
      laurel01,
      laurel02,
      laurel03,
      laurel04,
      laurel05,
      laurel06,
      laurel07,
      laurel08,
      laurel09,
      laurel10,
    ];
    return (
      <div className="home__laurels container">
        <Slider {...settings}>
          {laurels.map((laurel) => {
            return (
              <div className="text-center">
                <img src={laurel} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default HomeLaurels;
