import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./style.css";
import Homepage from "./components/Homepage";
import Page from "./components/Page";
import Nav from "./components/Nav";
import Post from "./components/Post";
import Noticias from "./components/Noticias";
import Footer from "./components/Footer";

class App extends Component {
  state = {
    openNav: false,
    lang: "pt-br"
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const openNav = false;
      this.setState({ openNav });
    }
  }
  openNav = () => {
    const openNav = !this.state.openNav;
    this.setState({ openNav });
  };
  changeLang = lang => {
    console.log(lang);
    this.setState({ lang });
  };
  render() {
    const { lang, openNav } = this.state;
    return (
      <Router>
        <Nav
          openNav={openNav}
          handleOpenNav={this.openNav}
          handleChangeLang={this.changeLang}
          lang={this.state.lang}
        />
        <div className={openNav ? "appWrapper navOpen" : "appWrapper"}>
          <Route
            exact
            path="/"
            render={routeProps => (
              <Homepage {...routeProps} lang={this.state.lang} />
            )}
          />
          <Route
            path="/equipe"
            render={routeProps => (
              <Page
                {...routeProps}
                lang={this.state.lang}
                page={{
                  "pt-br": 107,
                  en: 105
                }}
              />
            )}
          />
          <Route
            path="/produtora"
            render={routeProps => (
              <Page
                {...routeProps}
                lang={this.state.lang}
                page={{
                  "pt-br": 127,
                  en: 129
                }}
              />
            )}
          />
          <Route
            path="/diretora"
            render={routeProps => (
              <Page
                {...routeProps}
                lang={this.state.lang}
                page={{
                  "pt-br": 173,
                  en: 175
                }}
              />
            )}
          />
          <Route
            path="/post/:slug/:id"
            render={routeProps => (
              <Post {...routeProps} lang={this.state.lang} />
            )}
          />
          <Route
            path="/noticias"
            render={routeProps => (
              <Noticias {...routeProps} lang={this.state.lang} />
            )}
          />
          <Footer lang={this.state.lang} />
        </div>
      </Router>
    );
  }
}

export default App;
