import React, { Component } from 'react';
import axios from 'axios';
import { apiUrl } from '../data';

import videoBg from '../assets/output.mp4';

import HomeVideos from './parts/HomeVideos';
import HomeFilme from './parts/HomeFilme';
import HomeProdutora from './parts/HomeProdutora';
import HomeQuotes from './parts/HomeQuotes';
import HomeNews from './parts/HomeNews';
import HomeDestaque from './parts/HomeDestaque';
import HomeLaurels from './parts/HomeLaurels';

const pages = {
  'pt-br': 7,
  en: 57,
};

const fetchPage = async (lang) => {
  const res = await axios.get(`${apiUrl}/wp-json/wp/v2/pages/${pages[lang]}`);
  return res.data;
};
const fetchPosts = async (lang) => {
  // if (lang === "pt-br") {
  let res = await axios.get(
    `${apiUrl}/wp-json/wp/v2/posts?per_page=8&orderby=menu_order&order=asc`
  );
  return res.data;
  // } else {
  //   let res = await axios.get(
  //     `${apiUrl}/wp-json/wp/v2/posts?lang=${lang}&per_page=4&orderby=menu_order&order=asc`
  //   );
  //   return res.data;
  // }
};

class Homepage extends Component {
  state = {
    content: {
      title: {},
      content: {},
      acf: {
        videos: [{ imagem: { sizes: {} } }],
        filme: {},
        produtora: {},
        quotes: [{}],
        news: {},
        destaque: {},
      },
    },
    posts: [{ title: {}, content: {}, acf: {} }],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.content.title.rendered) {
      this.getContent(this.props.lang);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      this.getContent(this.props.lang);
    }
  }
  getContent = async (lang) => {
    const content = await fetchPage(lang);
    const posts = await fetchPosts(lang);
    this.setState({ content, posts });
  };
  render() {
    const { content, posts } = this.state;
    return (
      <div className="home">
        {/* <button onClick={() => this.changeLang("en")}>English</button> */}
        <div className="home__hero">
          <div
            className="hero__content"
            dangerouslySetInnerHTML={{ __html: content.acf.hero }}
          />
          <div className="hero__shadow" />
          <video autoPlay muted loop id="videoBg">
            <source src={videoBg} type="video/mp4" />
          </video>
        </div>
        <HomeLaurels />
        {/* <HomeVideos videos={content.acf.videos} /> */}
        {content.acf.quotes.length ? (
          <HomeQuotes quotes={content.acf.quotes} />
        ) : (
          ''
        )}
        <HomeFilme filme={content.acf.filme} />
        {content.acf.destaque.exibir ? (
          <HomeDestaque destaque={content.acf.destaque} />
        ) : (
          ''
        )}
        {content.acf.news.exibir_campo ? (
          <HomeNews posts={this.state.posts} content={this.state.content} />
        ) : (
          ''
        )}

        {content.acf.produtora.exibir_campo ? (
          <HomeProdutora produtora={content.acf.produtora} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Homepage;
