import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../data";
import HomeNews from "./parts/HomeNews";
import PaginationNumbers from "./parts/Pagination";

const page = {
  "pt-br": 79,
  en: 81
};

const perPage = 12;

const fetchPage = async lang => {
  const res = await axios.get(`${apiUrl}/wp-json/wp/v2/pages/${page[lang]}`);
  return res.data;
};

const PostInner = props => {
  return (
    <React.Fragment>
      <h3 dangerouslySetInnerHTML={{ __html: props.post.title.rendered }} />
      <h4>
        <span
          dangerouslySetInnerHTML={{
            __html: props.post.acf.autor
          }}
        />
        {props.post.acf.externa ? (
          <small>
            <i className="fa fa-external-link ml-2" />
          </small>
        ) : (
          ""
        )}
      </h4>
    </React.Fragment>
  );
};

class Noticias extends Component {
  state = {
    content: {
      title: {},
      content: {}
    },
    posts: [{ title: {}, content: {}, acf: {} }],
    page: 1,
    totalPages: 0
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.content.title.rendered) {
      this.getContent(this.props.lang);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      this.getContent(this.props.lang);
    }
  }
  getContent = async lang => {
    const content = await fetchPage(lang);
    const posts = await this.fetchPosts(lang, this.state.page);
    this.setState({ content, posts });
  };
  fetchPosts = async (lang, page) => {
    // if (lang === "pt-br") {
    let res = await axios.get(
      `${apiUrl}/wp-json/wp/v2/posts?per_page=${perPage}&page=${page}&orderby=menu_order&order=asc`
    );
    const totalPages = parseInt(res.headers["x-wp-totalpages"]);
    this.setState({ totalPages });
    return res.data;
    // } else {
    //   let res = await axios.get(
    //     `${apiUrl}/wp-json/wp/v2/posts?lang=${lang}&per_page=${perPage}&page=${page}&orderby=menu_order&order=asc`
    //   );
    //   const totalPages = parseInt(res.headers["x-wp-totalpages"]);
    //   this.setState({ totalPages });
    //   return res.data;
    // }
  };

  _handlePageChange = async page => {
    const posts = await this.fetchPosts(this.props.lang, page);
    this.setState({ posts }, () => {
      this.setState({ page });
    });
    window.scrollTo(0, 0);
  };
  render() {
    const { content, posts } = this.state;
    return (
      <div className="noticias">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                dangerouslySetInnerHTML={{ __html: content.title.rendered }}
              />
            </div>
            {posts.map(post => {
              return (
                <div className="col-12 col-md-6 col-xl-4">
                  {post.acf.externa ? (
                    <a
                      href={post.acf.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PostInner post={post} />
                    </a>
                  ) : (
                    <Link
                      to={{
                        pathname: `/post/${post.slug}/${post.id}`,
                        state: { post: post }
                      }}
                    >
                      <PostInner post={post} />
                    </Link>
                  )}
                </div>
              );
            })}
            <div className="col-12 mt-4">
              <PaginationNumbers
                totalPages={this.state.totalPages}
                currentPage={this.state.page}
                onPageChange={this._handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Noticias;
