import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../data";

const fetchPost = async id => {
  const res = await axios.get(`${apiUrl}/wp-json/wp/v2/posts/${id}`);
  return res.data;
};
class Post extends Component {
  state = {
    post: { title: {}, content: {}, acf: {} }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.state) {
      const post = this.props.location.state.post;
      this.setState({ post });
    } else {
      this.getPost(this.props.match.params.id);
    }
  }
  getPost = async id => {
    const post = await fetchPost(id);
    this.setState({ post });
  };
  render() {
    const { post } = this.state;
    return (
      <div className="thePost">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8  mt-4 mb-5">
              <i
                className="fa fa-arrow-left"
                onClick={() => this.props.history.goBack()}
              />

              <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
              <h4>{post.acf.autor}</h4>
              <div
                dangerouslySetInnerHTML={{ __html: post.content.rendered }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Post;
