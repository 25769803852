import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../data";

const page = {
  "pt-br": 120,
  en: 122
};

const fetchPage = async lang => {
  const res = await axios.get(`${apiUrl}/wp-json/wp/v2/pages/${page[lang]}`);
  return res.data;
};

class Footer extends Component {
  state = {
    content: {
      title: {},
      content: {}
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getContent(this.props.lang);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      this.getContent(this.props.lang);
    }
  }
  getContent = async lang => {
    const content = await fetchPage(lang);
    this.setState({ content });
  };
  render() {
    const { content } = this.state;
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                dangerouslySetInnerHTML={{
                  __html: content.title.rendered
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: content.content.rendered
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
