import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../data";

class Page extends Component {
  state = {
    content: {
      title: {},
      content: {}
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getContent(this.props.lang);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      this.getContent(this.props.lang);
    }
  }
  getContent = async lang => {
    const content = await this.fetchPage(lang);
    this.setState({ content });
  };
  fetchPage = async lang => {
    const res = await axios.get(
      `${apiUrl}/wp-json/wp/v2/pages/${this.props.page[lang]}`
    );
    return res.data;
  };

  render() {
    const { content } = this.state;
    return (
      <div className="page container">
        <div className="row">
          <div className="col-12 col-md-10">
            <i
              className="fa fa-arrow-left"
              onClick={() => this.props.history.goBack()}
            />
            <h2 dangerouslySetInnerHTML={{ __html: content.title.rendered }} />
            <div
              dangerouslySetInnerHTML={{ __html: content.content.rendered }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
