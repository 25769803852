import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeProdutora extends Component {
  state = {};
  render() {
    const { produtora } = this.props;
    return (
      <div className="home__producao">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{produtora.titulo}</h2>
            </div>
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: produtora.texto
                }}
              />

              <Link to="/produtora">
                <button>{produtora.botao}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeProdutora;
