import React from "react";

const HomeDestaque = props => {
  const { destaque } = props;
  return (
    <div className="home__destaque">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 dangerouslySetInnerHTML={{ __html: destaque.titulo }} />
          </div>
          <div
            className={
              destaque.imagem ? "col-12 col-md-6 align-self-center" : "col-12"
            }
          >
            <div
              className="lead"
              dangerouslySetInnerHTML={{ __html: destaque.texto }}
            />
          </div>
          <div
            className={
              destaque.imagem ? "col-12 col-md-6 align-self-center" : "d-none"
            }
          >
            <img src={destaque.imagem.url} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDestaque;
