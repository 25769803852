import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class Nav extends Component {
  state = {
    openNav: false
  };
  componentDidMount() {
    this.setState({ openNav: this.props.openNav });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.openNav !== this.props.openNav) {
      this.setState({ openNav: this.props.openNav });
    }
  }
  render() {
    return (
      <Fragment>
        <header>
          <nav>
            <div className="nav-right">
              <ul>
                {/* <li
                  className="nav__trigger"
                  onClick={() => this.props.handleOpenNav()}
                >
                  <div>
                    <i className="fa fa-bars" />
                  </div>
                </li> */}
                {this.props.lang === "pt-br" ? (
                  <li
                    className="nav__lang float-right"
                    onClick={() => this.props.handleChangeLang("en")}
                  >
                    <div>English</div>
                  </li>
                ) : (
                  <li
                    className="nav__lang float-right"
                    onClick={() => this.props.handleChangeLang("pt-br")}
                  >
                    <div>Português</div>
                  </li>
                )}
              </ul>
            </div>
            <Link to="/">
              <h4>
                {this.props.lang === "pt-br"
                  ? "BABENCO – Alguém tem que ouvir o coração e dizer: Parou"
                  : "BABENCO – Tell Me When I Die"}
              </h4>
            </Link>
          </nav>
        </header>
        <div className={this.state.openNav ? "sideNav navOpen" : "sideNav"}>
          <div
            className={
              this.state.openNav ? "sideNav__inner navOpen" : "sideNav__inner"
            }
          >
            <ul className="sideNav__menu">
              <Link to="/equipe">
                <li>
                  <div>Equipe</div>
                </li>
              </Link>
              <li>
                <div>Quotes</div>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Nav;
