import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeFilme extends Component {
  state = {};
  render() {
    const { filme } = this.props;
    return (
      <div className="home__filme">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{filme.titulo}</h2>
            </div>
            <div className="col-lg-6 col-xl-8">
              <div
                dangerouslySetInnerHTML={{
                  __html: filme.sinopse
                }}
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <div dangerouslySetInnerHTML={{ __html: filme.equipe }} />

              {filme.botao_equipe ? (
                <div>
                  <Link to="/equipe">
                    <button>{filme.botao_equipe}</button>
                  </Link>
                </div>
              ) : (
                ""
              )}

              {filme.botao_diretora ? (
                <div>
                  <Link to="/diretora">
                    <button>{filme.botao_diretora}</button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeFilme;
