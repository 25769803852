import React, { Component } from "react";
import { Link } from "react-router-dom";

const PostInner = props => {
  return (
    <React.Fragment>
      <h4
        dangerouslySetInnerHTML={{
          __html: props.post.acf.autor
        }}
      />
      <p>
        <span dangerouslySetInnerHTML={{ __html: props.post.title.rendered }} />
        {props.post.acf.externa ? (
          <small>
            <i className="fa fa-external-link ml-2" />
          </small>
        ) : (
          ""
        )}
      </p>
    </React.Fragment>
  );
};

class HomeNews extends Component {
  state = {};
  render() {
    const { posts, content } = this.props;
    return (
      <div className="home__news">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{content.acf.news.titulo}</h2>
            </div>
            {posts.map(post => {
              return (
                <div className="col-md-6 col-xl-3">
                  {post.acf.externa ? (
                    <a
                      href={post.acf.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PostInner post={post} />
                    </a>
                  ) : (
                    <Link
                      to={{
                        pathname: `/post/${post.slug}/${post.id}`,
                        state: { post: post }
                      }}
                    >
                      <PostInner post={post} />
                    </Link>
                  )}
                </div>
              );
            })}
            <div className="col-12">
              <Link to="/noticias">
                <button>{content.acf.news.botao}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeNews;
