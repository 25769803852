import React, { Component } from 'react';
import Slider from 'react-slick';

class HomeQuotes extends Component {
  state = {};
  hello() {
    var x = 8;
    console.log(x);
    var y = x * 3;
    console.log(y);
  }
  render() {
    this.hello();
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 4000,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      draggable: true,
      autoplay: true,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const { quotes } = this.props;
    return (
      <div className="home__quotes">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                {quotes.map((quote) => {
                  return (
                    <div className="quoteSlide">
                      <h6>"</h6>
                      <h4>{quote.texto}</h4>
                      <p>{quote.autor}</p>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeQuotes;
