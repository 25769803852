import React, { Component } from 'react';

class HomeVideos extends Component {
  state = {};
  render() {
    const { videos } = this.props;
    return (
      <div className="home__videos">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="current_video">a</div>
            </div>
            {videos.map((video) => {
              if (video.youtube_url) {
                var idYoutube = video.youtube_url.split('v=')[1];
                var ampersandPosition = idYoutube.indexOf('&');
                if (ampersandPosition !== -1) {
                  idYoutube = idYoutube.substring(0, ampersandPosition);
                }
                video.embed_url = `https://www.youtube.com/embed/${idYoutube}?rel=0`;
              } else if (video.vimeo_url) {
                var idVideo = video.vimeo_url.substring(
                  video.vimeo_url.lastIndexOf('/') + 1
                );
                video.embed_url = `https://player.vimeo.com/video/${idVideo}`;
              }
              return (
                <div className="col-4">
                  <img src={video.imagem.sizes.medium} alt="" />
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      src={video.embed_url}
                      title="Iframe"
                      allowFullScreen
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default HomeVideos;
